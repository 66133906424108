import "./disclaimer.css";

export const Disclaimer = (props) => {
  return (
    <div id="disclaimer">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Disclaimer:
            </p>
            <p>
              Lynn McKelvey is an Investment Advisor Representative of McKelvey Wealth Management, LLC, a Registered Investment Advisor firm registered in the State of Oregon.  McKelvey Wealth Management only provides investment advisory services where it is appropriately registered or exempt from registration and only to those individuals or entities who have received our Form ADV Part 2A, our <a href="/documents/privacy-policy-october-21.pdf" target="_blank">Privacy Policy</a> and executed an Investment Advisory Agreement confirming the services being provided.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
