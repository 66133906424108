export const Services = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="section-title">
          {props.data && props.data.title ? (
            <h2>{props.data.title}</h2>
          ) : (
            <h2>Our Services</h2>
          )}
        </div>
        <div className="row text-left">
          {props.data && props.data.paragraphs
            ? props.data.paragraphs.map((paragraph, index) => (
                <p className="col-xs-12" key={index}>
                  {paragraph}
                </p>
              ))
            : "loading paragraphs..."}
          {/* {props.data && props.data.items
            ? props.data.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
