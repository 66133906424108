import "./contact.css";
import { Map } from "./map";

export const Contact = (props) => {
  return (
      <div id="contact">
        <div className="container">
            <div className="section-title">
              <h2>Get In Touch</h2>
            </div>
            <div className="row">
              <div className="contact-item col-xs-12 col-md-4">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  <span>
                    {props.data ? props.data.address.street : "loading"}
                  </span>
                  <span>{props.data ? props.data.address.suite : "loading"}</span>
                  <span>{props.data ? props.data.address.city : "loading"}</span>
                </p>
              </div>
              <div className="contact-item col-xs-12 col-md-4">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data ? (
                    <a className="link" href={"tel:" + props.data.phone.link}>
                      {props.data.phone.display}
                    </a>
                  ) : (
                    "loading"
                  )}
                </p>
              </div>
              <div className="contact-item col-xs-12 col-md-4">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? (
                    <a className="link" href={"mailto:" + props.data.email}>
                      {props.data.email}
                    </a>
                  ) : (
                    "loading"
                  )}
                </p>
              </div>
            </div>
          {/* Map */}
          {/* <div className="col-md-7 col-xs-12">
            <a 
              href="https://www.google.com/maps/place/7110+SW+Fir+Loop,+Tigard,+OR+97223/@45.4238041,-122.7517959,17z/data=!4m6!3m5!1s0x54950cd393fc9c6d:0xa376e01ce8987ceb!8m2!3d45.4238907!4d-122.749457!16s%2Fg%2F11bw3xldj7?entry=ttu"
              target="_blank"
            >
              <div className="map-container">
                <div className="map" style={{ backgroundImage: "url(/img/lynn-map.png)" }}></div>
              </div>
            </a>
          </div> */}
        </div>
      </div>
  );
};
