import "./about.css";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-2">
            {" "}
            <img
              src="img/lynn.jpg"
              className="lynn-image img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-sm-10">
            <div className="about-text">
              <h2>About</h2>
              <p>
                {props.data && props.data.firstParagraph
                  ? props.data.firstParagraph
                  : "loading..."}
              </p>
              <p>
                {props.data && props.data.secondParagraph
                  ? props.data.secondParagraph
                  : ""}
              </p>
              <p>
                {props.data && props.data.thirdParagraph
                  ? props.data.thirdParagraph
                  : ""}
              </p>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
